import {
  treatmentSiteService
} from '../index';

export async function findTreatmentSites({ limit, skip, stages, search, sortField, sortDirection }) {
  try {
    console.log(`requesting TreatmentSites: ${limit}, ${search}`);
    const { data, total } = await treatmentSiteService.find({
      query: {
        $limit: limit,
        $skip: skip,
        $or: [
          {name: {$like:`%${search}%`}},
          {state: {$like:`%${search}%`}},
          {suburb: {$like:`%${search}%`}},
          {postCode: {$like:`%${search}%`}}
        ],
        $sort: {
          [sortField]: sortDirection,
        },
      }
    });

    const output = {total, data, toAppend: skip > 0};
    return output;
      
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function getTreatmentSite(payload) {
  try {
    return await treatmentSiteService.get(payload);
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function patchTreatmentSite(details) {
  try {
    console.log(details);
    return await treatmentSiteService.patch(details.id, details);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createTreatmentSite(treatmentSite) {
  try {
    return await treatmentSiteService.create(treatmentSite);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function deleteTreatmentSite(treatmentSiteId) {
  try {
    return await treatmentSiteService.remove(treatmentSiteId);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}