import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import NavButton from "components/NavButton";
import Sidebar from "components/Sidebar"
import { _required, _email, _mobile } from "util/validation"
import PatientForm from './components/PatientForm';

class PatientCreate extends Component {
  // constructor() {
  //   super();
  //   this.state = {
  //     redirectTo: '',
  //     form: {
  //       otherDebtTreatement: 'assessed',
  //     }
  //   };
  // }

  // submitForm = data => {
  //   // this.props.requestCreateLender({
  //   //   data
  //   // });
  //   // this.redirect('/lenders');
  // }

  // redirect = (to) => this.setState({ redirectTo: to });

  render() {
    // const { redirectTo } = this.state;

    // if (redirectTo) {
    //   return <Redirect to={redirectTo} />;
    // }

    return (
      <main className="dashboard details-page">
        <Sidebar />
        <div className="content">
          <div className="header">
            <NavButton className="button-type-2" to="/patient">Back</NavButton>
          </div>
          <div className='default-form'>
            <h1>Patient details</h1>
            <PatientForm type="update" history={this.props.history} />
          </div>
        </div>
      </main>
    );
  }

};


// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     // { requestCreateLender },
//     dispatch
//   );

PatientCreate = reduxForm({
  form: 'PatientCreate' // a unique name for this form
})(PatientCreate);


export default connect(null, null)(PatientCreate);

// export default ;