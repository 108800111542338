import React, { Component } from 'react';
import { Row, Cell } from '../Hcp';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestHcpLogin } from 'modules/user/actions';

const mapDispatchToProps = dispatch => 
  bindActionCreators({
    requestHcpLogin
  }, dispatch);

class HcpTableItemComponent extends Component {

  logInAsHcp() {

    let siteDomain;
    if (process.env.NODE_ENV === 'development') {
      siteDomain = 'http://localhost:3000';
    } else {
      if (window.location.hostname.indexOf('stag') !== -1) {
        siteDomain = 'https://staging.medax.net.au';
      } else if (window.location.hostname.indexOf('training') !== -1) {
        siteDomain = 'https://training.medax.net.au';
      } else {
        siteDomain = 'https://www.medax.net.au';
      }
    }

    this.props.requestHcpLogin({data: {
        email: this.props.hcp.email
      },
      callback: (result)=>{
        window.open(`${siteDomain}?email=${this.props.hcp.email}&loginToken=${result.token}&type=admin`, '_blank');
      }
    })
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.hcp.id === nextProps.hcp.id)
      return false;
    return true;
  }
  render() {
    const { hcp, onClick, ...props } = this.props;

    let hcpStatus = 'active';
    if (hcp.signUpStage < 0) {
      hcpStatus = 'deregistered';
    } else if (hcp.signUpStage < 3) {
      hcpStatus = 'unregistered';
    } else if (hcp.signUpStage == 3) {
      hcpStatus = 'registered';
    } else if (hcp.signUpStage == 4) {
      hcpStatus = 'pending';
    }

    return (<Row onClick={onClick} {...props}>

      <Cell width='15%'>{hcp.firstName || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12.5%'>{hcp.lastName || <span className='grey-text'>—</span>}</Cell>
      <Cell width='20%'>{hcp.email || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12.5%'>{hcp.mobileNumber || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12.5%'>{hcpStatus|| <span className='grey-text'>—</span>}</Cell>
      <Cell width='12.5%'>{hcp.state || <span className='grey-text'>—</span>}</Cell>
      <Cell width='15%'>
        <button onClick={()=>this.logInAsHcp()} className='button-type-2'>Login</button>
      </Cell>
{/* 
      <Cell width='12%'>{hcp.lastName || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12%'>{hcp.firstName || <span className='grey-text'>—</span>}</Cell>
      <Cell width='13%'>{hcp.phoneNumber && <a href={`tel:${hcp.phoneNumber}`}>{hcp.phoneNumber}</a> || <span className='grey-text'>—</span>}</Cell>
      <Cell width='24%'>{hcp.email && <a href={`mailto:${hcp.email}`}>{hcp.email}</a> || <span className='grey-text'>—</span>}</Cell>
      <Cell width='12%'>{clientStage() || <span className='grey-text'>—</span>}</Cell>
      <Cell width='15%'>{hcp.lastLogin && `${new Date(hcp.lastLogin).toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit' })}, `
        + `${new Date(hcp.lastLogin).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}`
        || <span className='grey-text'>—</span>}
      </Cell>
      <Cell width='15%'>
        <button onClick={()=>this.logInAsHcp()} className='btn btn-secondary'>Login</button>
      </Cell> */}
    </Row>);
  }
}

export default connect(null, mapDispatchToProps) (HcpTableItemComponent);

