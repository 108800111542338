import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, reset } from 'redux-form';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import NavButton from "components/NavButton";

import { bindActionCreators } from 'redux';
import { Field, Select, Checkbox } from "components/Forms"
import { boolean, gender, treatmentStablised, drugAddiction, weeklyDrugs, monthlyDrugs, durationOfInitialTreatment, patientStatus, previousTreatment } from "util/selectValues";
import filterInitialValues from 'util/filterInitialValues'
import { requestPatchPatient, requestPatient } from 'modules/patient/actions'
import { getQueryVariables } from 'util/index';

import { _required, _email, _mobile } from "util/validation"
let required = _required();
let email = _email();
let mobile = _mobile();
const requireYes = value => value !== 'false' ? undefined : 'Patient cannot be enrolled if requirement not met.';
let durationOfInitialTreatmentValidation = value => value !== "Days: Less than 7" ? undefined : 'Initial treatment duration must be at least 7 days.';

let requiredGroup = (value, allValues) => {
  if (!allValues.patient) return undefined;

  var emailExists = (allValues.patient.email !== undefined && allValues.patient.email !== '');
  var mobileNumberExists = (allValues.patient.mobileNumber !== undefined && allValues.patient.mobileNumber !== '');
  var resourcesCheckedExists = !!(allValues.patient.resourcesCheck);

  if (emailExists || mobileNumberExists || resourcesCheckedExists) {
    return undefined;
  }

  return 'This field is required.';
}

let administrationDateValidation = (value, allValues) => {
  let shipmentDate = moment(allValues.patient.shipmentDate, dateFormatString);
  let currentDate = moment();
  if (currentDate.hour() >= 12) {
    currentDate.add(1, 'd');
  }
  if (shipmentDate.isBefore(currentDate, 'day')) {
    return "Cannot ship in time for given administration date";
  } else {
    return undefined;
  }
};

const dateFormatString = 'ddd Do MMMM YYYY';

function mapStateToProps(state) {
  let prescriber = state.patients.current.prescriberId || {firstName:'', lastName:''};
  let manager = state.patients.current.userId || {firstName:'', lastName:''};
  let patient = { ...state.patients.current };

  if (patient.drugAddiction) {
    patient.drugAddiction = JSON.parse(patient.drugAddiction);
  }
  
  return {
    patient,
    PatientForm: state.form.PatientForm || {},
    treatmentSites: state.user.treatmentSites && state.user.treatmentSites.map(site=>({value:site.id, label:site.name})),
    initialValues: {
      patient: patient && {...patient, userId: ((patient.userId && patient.userId.id) || null)},
      prescriber: `${prescriber.firstName} ${prescriber.lastName} - ${prescriber.email}`,
      manager: `${manager.firstName} ${manager.lastName}`
    },
    fields: (state.form.PatientForm && state.form.PatientForm.registeredFields) || {},
    hcps: state.user.hcps && state.user.hcps.map(hcp=>({value:hcp.id, label:`${hcp.firstName} ${hcp.lastName} - ${hcp.email}`})),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchPatient,
    requestPatient,
    reset
  }, dispatch);
}

class PatientForm extends Component {

  componentWillMount() {
    const { id } = getQueryVariables(window.location.search);
    this.props.requestPatient({query: id, callback: () => {
      this.setState({ loaded: true });
      this.props.reset();
    }});
    this.setState({ patientId: id });
  }

  // submit(_results) {

  //   return new Promise((resolve, reject) => {
  //     _results.patient.initials = _results.patient.initials && _results.patient.initials.toUpperCase();
  //     _results.patient.dob = moment(_results.patient.dob).format('YYYY-MM-DD');

  //     let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);

  //     if (!results.patient || results.patient.length === 0) {
  //       this.props.history.push({
  //         pathname: "/buvidal/patients/onboarding-drug-order/?patientId=" + this.props.patient.id,
  //         data: this.props.patient.id
  //       });
  //     }

  //     results.patient.id = (this.props.patient) ? this.props.patient.id : "create";
  //     results.patient.state = "incomplete";
  //     results.patient.treatmentSiteId = _results.patient.treatmentSiteId;

  //     this.props.requestPatchPatient({
  //       data: results.patient,
  //       callback: (result) => {
  //         if (!result.error) {
  //           let patientId = (this.props.patient) ? this.props.patient.id : result.patients[result.patients.length - 1].id;
  //           this.props.history.push({
  //             pathname: "/buvidal/patients/onboarding-drug-order/?patientId=" + patientId,
  //             data: {
  //               patientId: (this.props.patient) ? this.props.patient.id : result.patients[result.patients.length - 1].id
  //             }
  //           });
  //           return resolve();
  //         } else {
  //           return reject(new SubmissionError({
  //             _error: result.error.message
  //           }));
  //         }
  //       }
  //     });
  //   });
  // }

  setShipmentDate(administrationDate) {

    let adminDate = moment(administrationDate.value || administrationDate);
    let oldAdminDate = moment(this.props.patient.administrationDate);

    if (this.props.patient) {
      let currentShipmentDate = moment(this.props.patient.shipmentDate);
      if (oldAdminDate.isBefore(currentShipmentDate, 'd')) {
        if (this.props.patient.oldDose ? this.props.patient.oldDose < 64 : this.props.patient.orderQuantity < 64) {
          adminDate.add(7, 'days');
          if (oldAdminDate.add(7, 'days').isBefore(currentShipmentDate, 'd')) {
            adminDate.add(7, 'days');
          }
        } else {
          adminDate.add(4, 'weeks');
        }
      }
    }
    let businessDays = this.props.isRural ? 4 : 2;

    let shipmentDate = adminDate;
    while (businessDays > 0 || shipmentDate.day() >= 5 || shipmentDate.day() === 0) {
      shipmentDate.subtract(1, 'd');
      if (!(shipmentDate.day() === 0 || shipmentDate.day() === 6)) {
        if (businessDays === 2) {
          let deliveryDate = shipmentDate.format('DD/MM/YY');
          this.setState(state=>({...state, deliveryDate}))
        }
        businessDays -= 1;
      }
    }

    this.props.change('user.patient.shipmentDate', shipmentDate.format(dateFormatString));
  }

  submit(_results) {
    return new Promise((resolve, reject) => {
      if (_results.patient.password) {
        if (_results.patient.password !== _results['password_confirm']) {
          return reject(new SubmissionError({
            'password_confirm': 'Passwords do not match'
          }));
        }
      }

      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.history.push(`/patient/success${window.location.search}`)
        return resolve();
      }

      this.props.requestPatchPatient({
        data: {...results.patient, id: this.props.patient.id},
        callback: (result) => {
          if (!result.error) {
            this.props.history.push(`/patient/success${window.location.search}`)
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('.content').animate({
            scrollTop: window.$('.error').offset().top + 500
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {

    const state = [
      { value: 'active', label: 'Active' },
      { value: 'did-not-proceed', label: 'Did not proceed' },
      { value: 'adverse-event', label: 'Adverse event' },
    ];

    if (!(this.props.patient && this.props.patient.id)) {
      return '';
    }

    const {status, drugAddiction: drugAddiction_value} = this.props.PatientForm && this.props.PatientForm.values && this.props.PatientForm.values.patient ? this.props.PatientForm.values.patient : {}

    const showAddictionOther = drugAddiction_value && drugAddiction_value.includes('other')

    return (
      <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
        <input hidden autoComplete="off" />

        <h2>Patient details</h2>
        <Select validate={[required]} styling="float l" name="patient.treatmentSiteId" type="text" title="Treatment site" options={this.props.treatmentSites} />
        <Field validate={[required]} styling="float r" name="patient.initials" type="text" title="Initials" />
        <Select validate={[required]} date disableCalendar styling="float l" name="patient.dob" type="text" title="Date of birth" />
        <Select validate={[required]} styling="float r" name="patient.sex" type="text" title="Sex" options={gender} />
        <Select validate={[required]} isMulti styling="float l" name="patient.drugAddiction" type="text" title="Primary drug of addition" options={drugAddiction} />
        {showAddictionOther && <Field validate={[required]} styling="float r" name="patient.drugAddiction_other" type="text" title="Other primary drug of addiction" />}
        <Select validate={[required]} styling={`float ${showAddictionOther ? 'l' : 'r'}`} name="patient.status" type="text" title="Patient status" options={patientStatus} />
        {status === 'Returning to treatment' && <Select validate={[required]} styling="float r" name="patient.previousTreatment" type="text" title="Previous treatment" options={previousTreatment} />}


        <h2>HCP details</h2>
        <Field disabled styling="float l" name="prescriber" type="text" title="Prescriber" />

        <h2>Patient status</h2>
        <Checkbox validate={[required]} radio title="" styling="float r" name="patient.state" options={state} />

        <div className="navigation">
          <button type="submit" className="primary button-type-1">Save</button>
          {this.props.type == "update" ?
            <NavButton className="secondary button-type-3" to={"/patient/delete" + window.location.search}>Delete</NavButton>
            : ""
          }
        </div>

      </form>
    );
  }
}

PatientForm = reduxForm({
  enableReinitialize: true,
  form: 'PatientForm' // a unique name for this form
})(PatientForm);

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
