import {
  RECEIVE_FIND_HCPS,
  RECEIVE_USER,
  RECIEVE_PATCH_USER,
  RECIEVE_CREATE_USER,
  RECIEVE_DELETE_USER,
  CLEAR_CURRENT_HCP
} from './actions';



const hcpReducer = (state = { current: {}, list: {list: []}}, action) => {
  switch(action.type) {
  case RECEIVE_FIND_HCPS: {
    const { data, total } = action.payload;
    console.log(action.payload);
    if (action.payload.toAppend) {
      const isComplete = !(data && data.length > 0);
      return { ...state, list: { list: [...state.list.list, ...data], total, isComplete } };
    }
    return { ...state, list: { isComplete: false, list: data, total } };
  }
  case RECEIVE_USER:
    return { ...state, current: action.payload }; 
  case RECIEVE_PATCH_USER: {
    const user = action.payload;
    const index = state.list.list.findIndex(u => u.id === user.id);
    if (index > -1) {
      let list = state.list.list;
      
      list.splice(index, 1);
      return {...state, current: user, list: {list: [...list, user]}};
    } else {
      return {...state, current: user};
    }
  }
  case RECIEVE_CREATE_USER: {
    return {...state, list: {list: []}};
  }
  case RECIEVE_DELETE_USER: {
    return {...state, list: {list: state.list.list.filter(user=>user.id !== action.payload)}};
  }
  case CLEAR_CURRENT_HCP: {
    return {...state, current: {}};
  }
  default:
    return state;
  }
};

export default hcpReducer;