import React from 'react';

export const LenderTable = (props) => (
  <div className='default-table' {...props}>
  </div>
);

export const HeadRow = (props) => (
  <div className='table-head-row' {...props}>
  </div>
);

export const Row = (props) => (
  <div className='table-row' {...props}>
  </div>
);

export const Cell = ({width, ...props}) => (
  <div className='table-cell' style={{width}} {...props}>
  </div>
);

export const SearchBar = (props) => (
  <input type='text' className='search-bar' placeholder='Search' {...props}/>
);

export const Header = () => (
  <div style={{display: 'table', width: 'calc(100%)'}}>
    <HeadRow>
      <Cell attributeName='firstName' width='15%'>First Name</Cell>
      <Cell attributeName='lastName' width='12.5%'>Last Name</Cell>
      <Cell attributeName='email' width='20%'>Email</Cell>
      <Cell attributeName='mobileNumber' width='12.5%'>Mobile</Cell> 
      <Cell attributeName='signUpStage' width='12.5%'>Status</Cell>
      <Cell attributeName='state' width='12.5%'>State</Cell>
      <Cell width='15%'>
      </Cell>
    </HeadRow>
  </div>
);

export const HcpTableItem = ({ lender, onClick }) => (
  <Row onClick={onClick}>
    <Cell width='15%'>{"lender.name" || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.bdmName || <span className='grey-text'>—</span>}</Cell>
    <Cell width='20%'>{lender.bdmEmail || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.bdmPhone || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.brokerPhone || <span className='grey-text'>—</span>}</Cell>
    <Cell width='12.5%'>{lender.brokerId || <span className='grey-text'>—</span>}</Cell>
    <Cell width='15%'>
      {lender.brokerWebsite ? 
        <a href={lender.brokerWebsite} target='_blank'>{lender.brokerWebsite}</a> 
        : <span className='grey-text'>—</span>
      }
    </Cell>
  </Row>
);

export const TablePlaceHolder = () => [
  <PlaceHolderRow key={0}/>,
];


export const PlaceHolderRow = index => (
  <Row key={index}>
    <Cell width='15%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='20%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='15%'></Cell>
  </Row>
)


