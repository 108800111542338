import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import NavButton from "components/NavButton";
import Sidebar from "components/Sidebar"
import { _required, _email, _mobile } from "util/validation"
import TreatmentSitesForm from './components/TreatmentSitesForm';

class HcpAdd extends Component {
  // constructor() {
  //   super();
  //   this.state = {
  //     redirectTo: '',
  //     form: {
  //       otherDebtTreatement: 'assessed',
  //     }
  //   };
  // }

  // submitForm = data => {
  //   // this.props.requestCreateLender({
  //   //   data
  //   // });
  //   // this.redirect('/lenders');
  // }

  // redirect = (to) => this.setState({ redirectTo: to });

  render() {
    // const { redirectTo } = this.state;

    // if (redirectTo) {
    //   return <Redirect to={redirectTo} />;
    // }

    return (
      <main className="dashboard details-page">
        <Sidebar />
        <div className="content">
          <div className="header">
            <NavButton className="button-type-2" to="/hcp">Back</NavButton>
          </div>
          <div className='default-form'>
            <h1>Create Treatment Site</h1>
            <TreatmentSitesForm buttonName="Create" isCreate={true} history={this.props.history} />
          </div>
        </div>
      </main>
    );
  }

};


// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     // { requestCreateLender },
//     dispatch
//   );

HcpAdd = reduxForm({
  form: 'HcpAdd' // a unique name for this form
})(HcpAdd);


export default connect(null, null)(HcpAdd);

// export default ;