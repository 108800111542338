import React, { Component } from "react";

import { reduxForm, SubmissionError, change } from 'redux-form';

import Sidebar from "components/Sidebar"
import { csvService } from "modules";

import { _required, _email, _mobile } from "util/validation"

class HCPs extends Component {

  submit(_results) {
    return new Promise(async (resolve, reject) => {
    

      let result = await csvService.create({ type: 'hcp' })

      if (result.error) {
        return reject(new SubmissionError({
          _error: result.error.message
        }));
      }

      const element = document.createElement("a");
      const file = new Blob([result], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "HCPs.csv";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();

      return resolve();     
    });
  }

  render() {
    return (
      <main className={"dashboard details-page"}>
        <Sidebar />
        <div className="content">
          <div className='default-form'>
            <h1>HCP List</h1>
              <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
                <div className="navigation">
                <button type="submit" className="button-type-1">Create CSV</button>
              </div>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

export default reduxForm({
  form: 'HCPs' // a unique name for this form
})(HCPs);


