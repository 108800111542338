import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router'

import { rootReducer, rootSaga, history } from './modules';
import gaMiddleware from './util/gaMiddleware'

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export default createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, routerMiddleware(history), gaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);
