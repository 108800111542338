import {
  userService
} from '../index';

export async function findHcps({ limit, skip, stages, search, sortField, sortDirection }) {
  try {
    console.log(`requesting Hcps: ${limit}, ${search}`);
    const { data, total } = await userService.find({
      query: {
        $limit: limit,
        $skip: skip,
        $or: [
          {firstName: {$like:`%${search}%`}},
          {lastName: {$like:`%${search}%`}},
          {email: {$like:`%${search}%`}},
          {mobileNumber: {$like:`%${search}%`}}
        ],
        $sort: {
          [sortField]: sortDirection,
        },
        role: '',

        // signUpStage: {
        //   $gt: 2
        // }
        //role: [null],
        // Hcpstage: {
        //   $in: stages,
        //   $nin: ['unregisted', 'unregistered', ''],
        // } 
        
      }
    });

    const output = {total, data, toAppend: skip > 0};
    return output;
      
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function getUser(payload) {
  try {
    return await userService.get(payload);
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function patchUser(details) {
  try {
    details = {...details, ...{
      lastLogin: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      fullName: details.firstName + ' ' + details.lastName,
    }};
    console.log(details);
    return await userService.patch(details.id, details);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createUser(user) {
  try {
    return await userService.create(user);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function deleteUser(userId) {
  try {
    return await userService.remove(userId);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}