// src/modules/users/api.js

import {
  app,
  userService,
  verificationService,
} from '../index';


export async function login(payload) {
  try {
    return await app.authenticate({
      strategy: 'local',
      ...payload,
    });
  } catch (err) {
    console.error("Authentication error: " + err);
    return {};
  }
}

export function logout() {
  app.logout();
}

export async function auth() {
  try {
    const token = await app.passport.getJWT();
    if (!token) {
      return {error: "No token"};
    }
    const payload = await app.passport.verifyJWT(token);
    const user = await userService.get(payload.userId);
    if (user.role !== "admin") {
      return {};
    }
    return user;
  } catch (err) {
    console.log(err);
    app.logout();
    return {error: err};
  }
}

export async function clientLogin(payload) {
  try {
    return await verificationService.create({
      type: "admin-login",
      email: payload.email
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

