import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, reset } from 'redux-form';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import NavButton from "components/NavButton";

import { bindActionCreators } from 'redux';
import { Field, Select, Checkbox } from "components/Forms"
import { state } from "util/selectValues";
import filterInitialValues from 'util/filterInitialValues'
import { requestPatchTreatmentSite, requestTreatmentSite, requestCreateTreatmentSite } from 'modules/treatmentSite/actions'
import { getQueryVariables } from 'util/index';

import { _required, _email, _mobile } from "util/validation"
let required = _required();
let email = _email();


let administrationDateValidation = (value, allValues) => {
  let shipmentDate = moment(allValues.treatmentSite.shipmentDate, dateFormatString);
  let currentDate = moment();
  if (currentDate.hour() >= 12) {
    currentDate.add(1, 'd');
  }
  if (shipmentDate.isBefore(currentDate, 'day')) {
    return "Cannot ship in time for given administration date";
  } else {
    return undefined;
  }
};

const dateFormatString = 'ddd Do MMMM YYYY';

function mapStateToProps(state, ownProps) {

  let initialValues;
  if (!ownProps.isCreate) {
    initialValues = {
      treatmentSite: state.treatmentSites.current
    }
  } else {
    initialValues = {
      treatmentSite: {
        allowAdditional: '1'
      }
    }
  }

  return {
    treatmentSite: state.treatmentSites.current,
    treatmentSites: state.user.treatmentSites && state.user.treatmentSites.map(site=>({value:site.id, label:site.name})),
    initialValues,
    fields: (state.form.TreatmentSiteForm && state.form.TreatmentSiteForm.registeredFields) || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchTreatmentSite,
    requestCreateTreatmentSite,
    requestTreatmentSite,
    reset
  }, dispatch);
}

class TreatmentSiteForm extends Component {

  componentWillMount() {
    const { id } = getQueryVariables(window.location.search);
    this.props.requestTreatmentSite({query: id, callback: () => {
      this.setState({ loaded: true });
      this.props.reset();
    }});
    this.setState({ treatmentSiteId: id });
  }

  submit(_results) {
    return new Promise((resolve, reject) => {
      if (_results.treatmentSite.password) {
        if (_results.treatmentSite.password !== _results['password_confirm']) {
          return reject(new SubmissionError({
            'password_confirm': 'Passwords do not match'
          }));
        }
      }

      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.history.push(`/treatment/success${window.location.search}`)
        return resolve();
      }

      let apiFunction = this.props.isCreate ? this.props.requestCreateTreatmentSite : this.props.requestPatchTreatmentSite;

      apiFunction({
        data: {...results.treatmentSite, id: this.props.treatmentSite.id},
        callback: (result) => {
          if (!result.error) {
            console.log(`/treatment/success${window.location.search}${this.props.isCreate?'&type=create':''}`);
            this.props.history.push(`/treatment/success/${window.location.search || '?type=create'}`)
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('.content').animate({
            scrollTop: window.$('.error').offset().top + 500
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    const status = [
      { value: '1', label: "Active" },
      { value: '', label: "Not Active" },
    ];

    const additional = [
      { value: '1', label: "Yes" },
      { value: '', label: "No" },
    ];
    const deliveryOptions = [
      { value: 'urban', label: "Urban" },
      { value: 'rural', label: "Rural" },
    ];

    const accountTypes = [
      { value: 'HCL', label: 'HCL' },
      { value: 'Symbion', label: 'Symbion' },
    ];

    if (!this.props.isCreate && !(this.props.treatmentSite && this.props.treatmentSite.id)) {
      return '';
    }

    return (
      <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">

        <h2>Account status</h2>
        <Checkbox radio title="Allow HCPs to register for this site?" styling="float r" name="treatmentSite.isActive" options={status} />

        {/* <h2>Additional 8mg</h2>
        <Checkbox radio title="Allow additional 8mg to be ordered?" styling="float r" name="treatmentSite.allowAdditional" options={additional} /> */}


        <h2>Account details</h2>
        <Checkbox styling="float" radio name="treatmentSite.account_type" options={accountTypes} validate={[required]} />
        <Field styling="float l" name="treatmentSite.ship_to_number" type="text" title="PFP Ship-to number" />
        <Field validate={[required]} styling="float l" name="treatmentSite.name" type="text" title="Account" />
        <Field styling="float r" name="treatmentSite.Account_number" type="text" title="Account number" />
        <Field styling="float l" name="treatmentSite.Address_1" type="text" title="Address line 1" />
        <Field styling="float r" name="treatmentSite.Address_2" type="text" title="Address line 2" />
        {/* <Field styling="float r" name="treatmentSite.Address_3" type="text" title="Site address 3" /> */}
        <Field validate={[]} styling="float l" name="treatmentSite.Suburb" type="text" title="Suburb" />
        <Field validate={[]} styling="float r" name="treatmentSite.Postcode" type="text" title="Postcode" />
        <Select validate={[]} styling="float l" name="treatmentSite.State" type="text" title="State" options={state} validate={[]} />
        <Field validate={[]} styling="float r" name="treatmentSite.Phone" type="text" title="Phone number" />
        <Field styling="" name="treatmentSite.Special_Shipping_Instructions" type="text" title="Special delivery instructions" />
        {/* <Checkbox radio styling="float" name="treatmentSite.delivery" title="Urban/Rural Classification" options={deliveryOptions} /> */}

        <h2>Contact details</h2>
        {/* <Field validate={[]} styling="float l" name="treatmentSite.Contact_title" type="text" title="Contact title" /> */}
        <Field validate={[]} styling="float l" name="treatmentSite.Contact_First" type="text" title="First name" />
        <Field validate={[]} styling="float r" name="treatmentSite.Contact_Last" type="text" title="Last name" />
        <Field validate={[email]} styling="float l" name="treatmentSite.Contact_Email" type="text" title="Email" />
        <Field validate={[]} styling="float r" name="treatmentSite.Contact_Phone" type="text" title="Phone number" />



        <div className="navigation">
          <button type="submit" className="primary button-type-1" disabled={this.props.pristine}>{this.props.buttonName}</button>
          {this.props.type == "update" ?
            <NavButton className="secondary button-type-3" to={"/treatment/delete" + window.location.search}>Delete</NavButton>
            : ""
          }
        </div>

      </form>
    );
  }
}


TreatmentSiteForm = reduxForm({
  enableReinitialize: true,
  form: 'TreatmentSiteForm' // a unique name for this form
})(TreatmentSiteForm);

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentSiteForm);
