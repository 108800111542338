import { createAction } from 'redux-actions';

export const REQUEST_FIND_HCPS = 'REQUEST_FIND_HCPS';
export const RECEIVE_FIND_HCPS = 'RECEIVE_FIND_HCPS';
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const REQUEST_PATCH_USER = 'REQUEST_PATCH_USER';
export const RECIEVE_PATCH_USER = 'RECIEVE_PATCH_USER';
export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER';
export const RECIEVE_CREATE_USER = 'RECIEVE_CREATE_USER';
export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
export const RECIEVE_DELETE_USER = 'RECIEVE_DELETE_USER';
export const CLEAR_CURRENT_HCP = 'CLEAR_CURRENT_HCP';

export const requestFindHcps = createAction(REQUEST_FIND_HCPS);
export const receiveFindHcps = createAction(RECEIVE_FIND_HCPS);

export const requestUser = createAction(REQUEST_USER);
export const receiveUser = createAction(RECEIVE_USER);

export const requestPatchUser = createAction(REQUEST_PATCH_USER);
export const receivePatchUser = createAction(RECIEVE_PATCH_USER);

export const requestCreateUser = createAction(REQUEST_CREATE_USER);
export const receiveCreateUser = createAction(RECIEVE_CREATE_USER);

export const requestDeleteUser = createAction(REQUEST_DELETE_USER);
export const receiveDeleteUser = createAction(RECIEVE_DELETE_USER);

export const clearCurrentHcp = createAction(CLEAR_CURRENT_HCP);