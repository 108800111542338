import {
  patientService
} from '../index';

export async function findPatients({ limit, skip, stages, search, sortField, sortDirection }) {
  try {
    console.log(`requesting Patients: ${limit}, ${search}`);
    const { data, total } = await patientService.find({
      query: {
        $limit: limit,
        $skip: skip,
        $or: [
          {initials: {$like:`%${search}%`}},
          {sex: {$like:`%${search}%`}},
          {dob: {$like:`%${search}%`}},
          {state: {$like:`%${search}%`}}
        ],
        $sort: {
          [sortField]: sortDirection,
        },
      }
    });

    const output = {total, data, toAppend: skip > 0};
    return output;
      
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function getPatient(payload) {
  try {
    return await patientService.get(payload);
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function patchPatient(details) {
  try {
    console.log(details);
    return await patientService.patch(details.id, details);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function createPatient(patient) {
  try {
    return await patientService.create(patient);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function deletePatient(patientId) {
  try {
    return await patientService.remove(patientId);
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}