// src/modules/users/sagas.js

import { takeEvery, call, put } from 'redux-saga/effects';

import {
  logout,
  login,
  auth,
  clientLogin
 } from './api';

import {
  receiveAuth,
  receiveLogout,
  requestAuth,

  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  REQUEST_AUTH,
  REQUEST_HCP_LOGIN
} from './actions';

function* callLogin({ payload: { data, callback } }) {
  let result = yield call(login, data);
  if (!result.accessToken) {
    callback ({
      success: false,
      message: "Invalid username or password"
    });
    return;
  }
  yield put(requestAuth((user)=>{
    callback ({
      success: !user.error,
      errorMessage: user.error && user.error.message
    });
  }));
}

export function* loginSaga() {
  yield takeEvery(REQUEST_LOGIN, callLogin);
}

function* callLogout({ payload: { path } }) {
  yield call(logout);
  let query = '';
  if (path) {
    query = `?next=${path}`;
  }
  window.location = window.location.origin + "/login" + query;    
}

export function* logoutSaga() {
  yield takeEvery(REQUEST_LOGOUT, callLogout);
}

function* callAuth({ payload }) {
  const user = yield call(auth);
  if (!user.error) {
    yield put(receiveAuth(user));
  }
  if (typeof(payload) === 'object') {
    if (user.error) {
      payload.onFail && payload.onFail(user.error);
    } else {
      payload.onSuccess && payload.onSuccess(user);
    }
  } else {
    payload(user);
  }
}

export function* authSaga() {
  yield takeEvery(REQUEST_AUTH, callAuth);
}

function* callClientLogin({ payload }) {
  let result = yield call(clientLogin, payload.data);
  payload.callback(result);
}

export function* clientLoginSaga() {
  yield takeEvery(REQUEST_HCP_LOGIN, callClientLogin);
}
