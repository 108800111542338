import React, { Component } from 'react';
import { Row, Cell } from '../TreatmentSites';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => 
  bindActionCreators({    
  }, dispatch);

class TreatmentSiteTableItem extends Component {

  shouldComponentUpdate(nextProps) {
    if (this.props.treatmentSite.id === nextProps.treatmentSite.id)
      return false;
    return true;
  }
  render() {
    const { treatmentSite, onClick, ...props } = this.props;
    console.log(treatmentSite)

    return (<Row onClick={onClick} {...props}>

      <Cell width='40%'>{treatmentSite.name || <span className='grey-text'>—</span>}</Cell>
      <Cell width='10%'>{treatmentSite.Suburb || <span className='grey-text'>—</span>}</Cell>
      <Cell width='10%'>{treatmentSite.State || <span className='grey-text'>—</span>}</Cell>
      <Cell width='10%'>{treatmentSite.Postcode || <span className='grey-text'>—</span>}</Cell>
      <Cell width='10%'>{(treatmentSite.isActive ? "active" : "not active") || <span className='grey-text'>—</span>}</Cell>
      <Cell width='10%'>{treatmentSite.Account_number || <span className='grey-text'>—</span>}</Cell>
      <Cell width='10%'>{treatmentSite.account_type || <span className='grey-text'>—</span>}</Cell>
    </Row>);
  }
}

export default connect(null, mapDispatchToProps) (TreatmentSiteTableItem);

