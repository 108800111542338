
import { takeEvery, call, put } from 'redux-saga/effects';

import {
  findPatients,
  getPatient,
  patchPatient,
  createPatient,
  deletePatient
} from './api';

import {
  receiveFindPatients,
  receivePatient,
  receivePatchPatient,
  receiveCreatePatient,
  receiveDeletePatient,
  clearCurrentPatient,

  REQUEST_FIND_PATIENTS,
  REQUEST_PATIENT,
  REQUEST_PATCH_PATIENT,
  REQUEST_CREATE_PATIENT,
  REQUEST_DELETE_PATIENT
} from './actions';



function* callFindPatients({ payload: { query, callback } }) {
  let result = yield call(findPatients, query);
  yield put(receiveFindPatients(result));
  if (callback)
    callback();
}

export function* findPatientsSaga() {
  yield takeEvery(REQUEST_FIND_PATIENTS, callFindPatients);
}

function* callGetPatient({ payload: { query, callback } }) {
  yield put(clearCurrentPatient());
  let result = yield call(getPatient, query);
  yield put(receivePatient(result));
  callback && callback(result);
}

export function* getPatientSaga() {
  yield takeEvery(REQUEST_PATIENT, callGetPatient);
}

function* callPatchPatient({ payload: { data, callback } }) {
  let result = yield call(patchPatient, data);
  if (!result.error) {
    yield put(receivePatchPatient(result));
  }
  callback && callback(result);
}

export function* patchPatientSaga() {
  yield takeEvery(REQUEST_PATCH_PATIENT, callPatchPatient);
}

function* callCreatePatient({ payload: { data, callback } }) {
  let result = yield call(createPatient, data);
  console.log('new patient:', result);
  if (!result.error) {
    yield put(receiveCreatePatient(result));
  }
  callback && callback(result);
}

export function* createPatientSaga() {
  yield takeEvery(REQUEST_CREATE_PATIENT, callCreatePatient);
}

function* callDeletePatient({ payload: { id, callback } }) {
  let result = yield call(deletePatient, id);
  console.log('delete patient:', result);
  if (!result.error) {
    yield put(receiveDeletePatient(id));
  }
  callback && callback(result);
}

export function* deletePatientSaga() {
  yield takeEvery(REQUEST_DELETE_PATIENT, callDeletePatient);
}