import {
  RECEIVE_FIND_PATIENTS,
  RECEIVE_PATIENT,
  RECIEVE_PATCH_PATIENT,
  RECIEVE_CREATE_PATIENT,
  RECIEVE_DELETE_PATIENT,
  CLEAR_CURRENT_PATIENT
} from './actions';



const clientReducer = (state = { current: {}, list: {list: []}}, action) => {
  switch(action.type) {
  case RECEIVE_FIND_PATIENTS: {
    const { data, total } = action.payload;
    console.log(action.payload);
    if (action.payload.toAppend) {
      const isComplete = !(data && data.length > 0);
      return { ...state, list: { list: [...state.list.list, ...data], total, isComplete } };
    }
    return { ...state, list: { isComplete: false, list: data, total } };
  }
  case RECEIVE_PATIENT:
    return { ...state, current: action.payload }; 
  case RECIEVE_PATCH_PATIENT: {
    const patient = action.payload;
    const index = state.list.list.findIndex(u => u.id === patient.id);
    if (index > -1) {
      let list = state.list.list;
      
      list.splice(index, 1);
      return {...state, current: patient, list: {list: [...list, patient]}};
    } else {
      return {...state, current: patient};
    }
  }
  case RECIEVE_CREATE_PATIENT: {
    return {...state, list: {list: []}};
  }
  case RECIEVE_DELETE_PATIENT: {
    return {...state, list: {list: state.list.list.filter(patient=>patient.id !== action.payload)}};
  }
  case CLEAR_CURRENT_PATIENT: {
    return {...state, current: {}};
  }
  default:
    return state;
  }
};

export default clientReducer;