
import { takeEvery, call, put } from 'redux-saga/effects';

import {
  findTreatmentSites,
  getTreatmentSite,
  patchTreatmentSite,
  createTreatmentSite,
  deleteTreatmentSite
} from './api';

import {
  receiveFindTreatmentSites,
  receiveTreatmentSite,
  receivePatchTreatmentSite,
  receiveCreateTreatmentSite,
  receiveDeleteTreatmentSite,
  clearCurrentTreatmentSite,

  REQUEST_FIND_TREATMENT_SITES,
  REQUEST_TREATMENT_SITE,
  REQUEST_PATCH_TREATMENT_SITE,
  REQUEST_CREATE_TREATMENT_SITE,
  REQUEST_DELETE_TREATMENT_SITE
} from './actions';



function* callFindTreatmentSites({ payload: { query, callback } }) {
  let result = yield call(findTreatmentSites, query);
  yield put(receiveFindTreatmentSites(result));
  if (callback)
    callback();
}

export function* findTreatmentSitesSaga() {
  yield takeEvery(REQUEST_FIND_TREATMENT_SITES, callFindTreatmentSites);
}

function* callGetTreatmentSite({ payload: { query, callback } }) {
  yield put(clearCurrentTreatmentSite());
  let result = yield call(getTreatmentSite, query);
  yield put(receiveTreatmentSite(result));
  callback && callback(result);
}

export function* getTreatmentSiteSaga() {
  yield takeEvery(REQUEST_TREATMENT_SITE, callGetTreatmentSite);
}

function* callPatchTreatmentSite({ payload: { data, callback } }) {
  console.log("PATCHING");
  let result = yield call(patchTreatmentSite, data);
  if (!result.error) {
    yield put(receivePatchTreatmentSite(result));
  }
  callback && callback(result);
}

export function* patchTreatmentSiteSaga() {
  yield takeEvery(REQUEST_PATCH_TREATMENT_SITE, callPatchTreatmentSite);
}

function* callCreateTreatmentSite({ payload: { data, callback } }) {
  let result = yield call(createTreatmentSite, data);
  console.log('new treatmentSite:', result);
  if (!result.error) {
    yield put(receiveCreateTreatmentSite(result));
  }
  callback && callback(result);
}

export function* createTreatmentSiteSaga() {
  yield takeEvery(REQUEST_CREATE_TREATMENT_SITE, callCreateTreatmentSite);
}

function* callDeleteTreatmentSite({ payload: { id, callback } }) {
  let result = yield call(deleteTreatmentSite, id);
  console.log('delete treatmentSite:', result);
  if (!result.error) {
    yield put(receiveDeleteTreatmentSite(id));
  }
  callback && callback(result);
}

export function* deleteTreatmentSiteSaga() {
  yield takeEvery(REQUEST_DELETE_TREATMENT_SITE, callDeleteTreatmentSite);
}