import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError, change } from 'redux-form';
import { Field, Select, Checkbox } from "components/Forms"
import moment from 'moment';

import Sidebar from "components/Sidebar"
import { csvService } from "modules";

import { _required, _email, _mobile } from "util/validation"
let required = _required();

function mapStateToProps(state) {
  return {
    initialValues: {
      history:{ startDate: moment(), endDate: moment() }
    },
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class OrderHistory extends Component {

  submit(_results) {
    return new Promise(async (resolve, reject) => {
      let results = {..._results};
      results.history.startDate = moment(results.history.startDate).format('YYYY-MM-DD');

      if (results.history.endDate) {
        results.history.endDate = moment(results.history.endDate).add(1, 'days').format('YYYY-MM-DD');
      }

      let result = await csvService.create({ type: 'orders', args: results.history })

      if (result.error) {
        return reject(new SubmissionError({
          _error: result.error.message
        }));
      }

      const element = document.createElement("a");
      const file = new Blob([result], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "Confirmed Orders.csv";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();

      return resolve();     
    });
  }

  render() {
    return (
      <main className={"dashboard details-page"}>
        <Sidebar />
        <div className="content">
          <div className='default-form'>
            <h1>Order History</h1>
            {/* <p>Leave the end date empty to get all the dates from the start date.</p> */}
              <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
                <Select validate={[]} date disableCalendar isClearable styling="float l" name="history.startDate" type="text" title="Start date" noOptionsMessage={() => null} />
                <Select validate={[]} date disableCalendar isClearable styling="float r" name="history.endDate" type="text" title="End date" noOptionsMessage={() => null} />

                <div className="navigation">
                <button type="submit" className="button-type-1">Create CSV</button>
              </div>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

OrderHistory = reduxForm({
  form: 'OrderHistory' // a unique name for this form
})(OrderHistory);

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
