import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavButton from "components/NavButton";
import { requestFindPatients } from 'modules/patient/actions';
import Sidebar from "components/Sidebar"
import {
  Header,
  TablePlaceHolder
} from './components/SearchComponents';
import PatientTableItem from './components/PatientTableItem';
import SearchBar from 'view/components/SearchBar';

const initialPageSize = 50;
const pageSize = 20;
const bottomLoadHeight = 1200;

class Patient extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: '',
      patientsLoaded: false,
      stages: [],
      searchText: '',
      sortField: 'initials',
      sortDirection: 1,
    };
  }

  componentWillMount = () => {
    console.log('component mounting');
    if (this.props.patients.length > 0) {
      this.setState({ patientsLoaded: true});
      return;
    }

    this.props.requestFindPatients({
      query: {
        limit: initialPageSize,
        skip: 0,
        stages: [],
        search: '',
        sortField: 'initials',
        sortDirection: 1,
      },
      callback: () => this.setState({ patientsLoaded: true }),
    });
  }

  redirect = (to) => this.props.history.push(to);

  render() {
    const patients = this.props.patients.list;
    const LengthToAdd = patients.list ? (patients.total - patients.list.length) * 70 : 0;

    return (
      <main className="dashboard content-page" onScroll={this.onScroll}>
        <Sidebar />
        <div className="content">
          {/* <h1>Health Care Professionals</h1> */}
          <div className='search-container'>
            <div className="search-bar">
              <SearchBar
                tabIndex={200}
                width='187px'
                placeholder='Search...'
                msDebounce={300}
                onChange={async searchText => {
                  await this.setState({ searchText });
                  this.filterResults();
                }}
              />
            </div>

            {/* <NavButton className="button-type-1" to='/patient/create' style={{ marginRight: '40px', float: 'right' }}>+ Add Patients</NavButton> */}

            <PatientTable>
              <Header />
              <div className='table-contents hcps'>
                <div style={{width: '100%', display:'table'}}>
                  {patients.list && patients.list.length > 0 ? patients.list.map((patient, index) => (
                    
                    <PatientTableItem
                      onClick={exclude(['a', 'button'], () => this.redirect(`/patient/update?id=${patient.id}`))}
                      tabIndex={300 + index}
                      key={index}
                      patient={patient}
                    />
                  )) : (
                    <TablePlaceHolder />
                  )}
                  <div style={{height: `${LengthToAdd}px`}}></div>
                </div>

              </div>
            </PatientTable>
          </div>
        </div>
      </main>
    )
  }

  onScroll = async e => {
    const patients = this.props.patients.list;
    const { patientsLoaded } = this.state;
    if (patients.listComplete) return;

    const currentFilledLength = patients.list.length * 70; 
    const scrollPosition = e.target.offsetHeight + e.target.scrollTop;
    
    if (scrollPosition < currentFilledLength - bottomLoadHeight) return;
    
    if (patientsLoaded) { //i.e. no currently pending calls to the DB
      this.setState({ patientsLoaded: false });
      const { searchText, sortField, sortDirection, stages } = this.state;
      
      //if user scrolls past currently loaded point increase page size to load to that point in only one call
      let limit = pageSize;
      if (((scrollPosition / 70) - patients.list.length) > pageSize) 
        limit = ((scrollPosition / 70) - patients.list.length) + 10; // + 10 to account for extra scrolling during load
      
      this.props.requestFindPatients({
        query: {
          limit,
          skip: patients.list.length,
          stages,
          search: searchText,
          sortField,
          sortDirection,
        },
        callback: () => {
          this.setState({ patientsLoaded: true });
        }});  
    }
    
  }

  async filterResults() {
    const {searchText, stages, sortDirection, sortField} = this.state;
    this.props.requestFindPatients({
      query: {
        limit: initialPageSize,
        skip: 0,
        stages,
        search: searchText,
        sortField,
        sortDirection,
      },
    });
  }
}

const exclude = (exclusions, fn) => event => {
  if (!event.target.matches(exclusions)) {
    fn(event);
  }
};

const nullCheckedIncludes = (string, filter) =>
  string && string.toLowerCase().includes(filter.toLowerCase());

const excludeLinks = (event, fn) => {
  if (!event.target.matches('a')) {
    fn(event);
  }
}

const mapStateToProps = ({ patients }) => ({ patients });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { requestFindPatients },
    dispatch
  );

const PatientTable = (props) => (
  <div className='default-table' {...props}>
  </div>
);

export const HeadRow = (props) => (
  <div className='table-head-row' {...props}>
  </div> 
);

export const Row = (props) => (
  <div className='table-row' {...props}>
  </div>
);

export const Cell = ({width, ...props}) => (
  <div className='table-cell' {...props} style={{width}}>
  </div>
);

const PlaceHolderRow = index => (
  <Row key={index}>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
    <Cell></Cell>
  </Row>
);  

export default connect(mapStateToProps, mapDispatchToProps)(Patient);