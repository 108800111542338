import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import Hcp from "./hcp/Hcp";
import HcpCreate from "./hcp/HcpCreate";
import HcpUpdate from './hcp/HcpUpdate';
import HcpDelete from './hcp/HcpDelete';
import HcpSuccess from './hcp/HcpSuccess';

import Patient from "./patient/Patient";
import PatientUpdate from "./patient/PatientUpdate";
import PatientDelete from "./patient/PatientDelete";
import PatientSuccess from "./patient/PatientSuccess";

import TreatmentSites from "./sites/TreatmentSites";
import TreatmentSitesCreate from "./sites/TreatmentSitesCreate";
import TreatmentSitesUpdate from "./sites/TreatmentSitesUpdate";
import TreatmentSitesDelete from "./sites/TreatmentSitesDelete";
import TreatmentSitesSuccess from "./sites/TreatmentSitesSuccess";

import OrderHistory from "./order/OrderHistory";
import PatientList from "./order/PatientList";
import StockReport from "./order/StockReport";
import UnconfirmedOrders from "./order/UnconfirmedOrders";
import HCPList from "./order/HCPs";


import Signin from './accounts/Signin';

class Routes extends Component {
  render() {

    if (!this.props.isLoggedIn) {
      return (
        <Switch>
          <Route exact path="/login" render={(props) => <Signin {...props} />} />
          <Redirect to="/login" />
        </Switch>
      )
    }

    return (
      <Switch>
        <Redirect exact from="/" to="/hcp" />

        <Route exact path="/hcp" render={(props) => <Hcp {...props} />} />
        <Route exact path='/hcp/create' render={(props) => <HcpCreate {...props} />} />
        <Route exact path='/hcp/update' render={(props) => <HcpUpdate {...props} />} />
        <Route exact path="/hcp/delete" render={(props) => <HcpDelete {...props} />} />
        <Route exact path="/hcp/success" render={(props) => <HcpSuccess {...props} />} />

        <Route exact path="/patient" render={(props) => <Patient {...props} />} />
        <Route exact path="/patient/update" render={(props) => <PatientUpdate {...props} />} />
        <Route exact path="/patient/delete" render={(props) => <PatientDelete {...props} />} />
        <Route exact path="/patient/success" render={(props) => <PatientSuccess {...props} />} />
        
        <Route exact path="/treatment" render={(props) => <TreatmentSites {...props} />} />
        <Route exact path="/treatment/create" render={(props) => <TreatmentSitesCreate {...props} />} />
        <Route exact path="/treatment/update" render={(props) => <TreatmentSitesUpdate {...props} />} />
        <Route exact path="/treatment/delete" render={(props) => <TreatmentSitesDelete {...props} />} />
        <Route exact path="/treatment/success" render={(props) => <TreatmentSitesSuccess {...props} />} />

        <Route exact path="/order" render={(props) => <OrderHistory {...props} />} />
        <Route exact path="/patientlist" render={(props) => <PatientList {...props} />} />
        <Route exact path="/stock" render={(props) => <StockReport {...props} />} />
        <Route exact path="/unconfirmed" render={(props) => <UnconfirmedOrders {...props} />} />
        <Route exact path="/hcplist" render={(props) => <HCPList {...props} />} />

        <Redirect to="/hcp" />
      </Switch>
    )
  }
}


export default withRouter(props => <Routes {...props} />);