import React, { Component } from "react";
import { connect } from 'react-redux';

import Sidebar from "components/Sidebar"
import NavButton from "components/NavButton";
import { getQueryVariables } from 'util/index';

class HcpSuccess extends Component {

  componentWillMount() {
    const { type } = getQueryVariables(window.location.search);
    this.setState(state=>({ type }));
  }

  render() {

    let title = 'Update Successful';
    let content = 'Your update has been successful.';
    let link = `/hcp/update/${window.location.search}`;
    
    if (this.state.type === 'delete') {
      title = 'Delete Successful';
      content = 'HCP deleted successfully.';
      link = '/hcp';
    }        
    if (this.state.type === 'create') {
      title = 'Create Successful';
      content = 'HCP created successfully.';
      link = '/hcp';
    }

    return (
      <main className={"dashboard details-page"}>
        <Sidebar />
        <div className="content">
          <div className='default-form'>
            <h1>{title}</h1>
            <p>{content}</p>
            <form>
              <div className="navigation">
                <NavButton to={link} className="button-type-1">Continue</NavButton>
              </div>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

export default connect(null, null)(HcpSuccess);
