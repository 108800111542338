export default function filterInitialValues(values, fields, initialVals) {
  let results = {};
  Object.keys(fields).forEach(field=>{
    let arr = field.split('.');
    let lastObject = results;
    let value = values;
    let initial = initialVals;
    arr.forEach((v, index)=>{
      if (index === arr.length - 1) {
        if (!initial || initial[v] !== (value && value[v])) {
          lastObject[v] = value && value[v];
          results.hasChanged = true;
        }
        return;
      }
      if (!lastObject[v]) {
        lastObject[v] = {};
      }
      lastObject = lastObject[v];
      value = value && value[v];
      initial = initial && initial[v];
    });
  });
  return results;
}