import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class ContainerBox extends Component {
  render() {
    return (
        <main className={"accounts " + this.props.type}>

            <div className="box-container">
                <div className="box-wrap">
                    <div className="box">
                        <div className={"loader " + (this.props.isLoading?"show":"")}>
                            <img className="spinner" src={require('../../../img/accounts/Spinner-1s-200px.svg')} alt="spinner"/>
                        </div>
                        <img className="logo" src={require('../../../img/medax/medax_logo.png')} alt="medax-logo"/>
                        <h1>{this.props.title}</h1>
                        {this.props.children}
                    </div>

                </div>

            </div>

        </main>
    )
  }
}


export default ContainerBox;