// src/modules/users/reducers.js

import {
  RECEIVE_AUTH,
  RECEIVE_LOGOUT,
} from './actions';

const userReducer = (state = {}, action) => {
  switch(action.type) {
    case RECEIVE_AUTH:
    case RECEIVE_LOGOUT:
      return action.payload;
    default:
      return state;
  }
};

export default userReducer;