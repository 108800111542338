import { createAction } from 'redux-actions';

export const REQUEST_FIND_TREATMENT_SITES = 'REQUEST_FIND_TREATMENT_SITES';
export const RECEIVE_FIND_TREATMENT_SITES = 'RECEIVE_FIND_TREATMENT_SITES';
export const REQUEST_TREATMENT_SITE = 'REQUEST_TREATMENT_SITE';
export const RECEIVE_TREATMENT_SITE = 'RECEIVE_TREATMENT_SITE';
export const REQUEST_PATCH_TREATMENT_SITE = 'REQUEST_PATCH_TREATMENT_SITE';
export const RECIEVE_PATCH_TREATMENT_SITE = 'RECIEVE_PATCH_TREATMENT_SITE';
export const REQUEST_CREATE_TREATMENT_SITE = 'REQUEST_CREATE_TREATMENT_SITE';
export const RECIEVE_CREATE_TREATMENT_SITE = 'RECIEVE_CREATE_TREATMENT_SITE';
export const REQUEST_DELETE_TREATMENT_SITE = 'REQUEST_DELETE_TREATMENT_SITE';
export const RECIEVE_DELETE_TREATMENT_SITE = 'RECIEVE_DELETE_TREATMENT_SITE';
export const CLEAR_CURRENT_TREATMENT_SITE = 'CLEAR_CURRENT_TREATMENT_SITE';

export const requestFindTreatmentSites = createAction(REQUEST_FIND_TREATMENT_SITES);
export const receiveFindTreatmentSites = createAction(RECEIVE_FIND_TREATMENT_SITES);

export const requestTreatmentSite = createAction(REQUEST_TREATMENT_SITE);
export const receiveTreatmentSite = createAction(RECEIVE_TREATMENT_SITE);

export const requestPatchTreatmentSite = createAction(REQUEST_PATCH_TREATMENT_SITE);
export const receivePatchTreatmentSite = createAction(RECIEVE_PATCH_TREATMENT_SITE);

export const requestCreateTreatmentSite = createAction(REQUEST_CREATE_TREATMENT_SITE);
export const receiveCreateTreatmentSite = createAction(RECIEVE_CREATE_TREATMENT_SITE);

export const requestDeleteTreatmentSite = createAction(REQUEST_DELETE_TREATMENT_SITE);
export const receiveDeleteTreatmentSite = createAction(RECIEVE_DELETE_TREATMENT_SITE);

export const clearCurrentTreatmentSite = createAction(CLEAR_CURRENT_TREATMENT_SITE);