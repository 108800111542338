import React, { Component } from "react";
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, change } from 'redux-form';

import Sidebar from "components/Sidebar"
import { csvService } from "modules";
import { Field, Select, Checkbox } from "components/Forms"

import { _required, _email, _mobile } from "util/validation"

class Stock extends Component {

  submit(_results) {
    return new Promise(async (resolve, reject) => {
    

      let result = await csvService.create({ type: 'stock', args: { siteId: _results.location } });

      if (result.error) {
        return reject(new SubmissionError({
          _error: result.error.message
        }));
      }

      const element = document.createElement("a");
      const file = new Blob([result], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "Stock.csv";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();

      return resolve();     
    });
  }

  render() {
    console.log(this.props.locations);
    const locationList = [
      {label: 'All', value: -1}, 
      ...this.props.locations
        .sort((_a, _b) => {
          const a = _a.name.toLowerCase();
          const b = _b.name.toLowerCase();
          if (a > b) return 1;
          if (a < b) return -1
          return 0;
        })
        .map((location => ({
          label: location.name,
          value: location.id,
        })))
    ];

    return (
      <main className={"dashboard details-page"}>
        <Sidebar />
        <div className="content">
          <div className='default-form'>
            <h1>Stock</h1>
            <p>Leave location empty to receive a report for all locations.</p>
              <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
                
              <Select value={-1} styling="float l" options={locationList} name="location" type="text" title="Location" noOptionsMessage={() => 'All'} />
                <div className="navigation">
                
                <button type="submit" className="button-type-1">Create CSV</button>
                
              </div>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

const stateToProps = state => ({
  locations: state.treatmentSites.fullList,
});

Stock = reduxForm({
  form: 'Stock', // a unique name for this form
  initialValues: {
    location: -1,
  }
})(Stock);

export default connect(stateToProps)(Stock);