import {
  RECEIVE_FIND_TREATMENT_SITES,
  RECEIVE_TREATMENT_SITE,
  RECIEVE_PATCH_TREATMENT_SITE,
  RECIEVE_CREATE_TREATMENT_SITE,
  RECIEVE_DELETE_TREATMENT_SITE,
  CLEAR_CURRENT_TREATMENT_SITE
} from './actions';


import {
  RECEIVE_AUTH,
} from '../user/actions';


const treatmentSiteReducer = (state = { current: {}, list: {list: []}}, action) => {
  switch(action.type) {
  case RECEIVE_FIND_TREATMENT_SITES: {
    const { data, total } = action.payload;
    console.log(action.payload);
    if (action.payload.toAppend) {
      const isComplete = !(data && data.length > 0);
      return { ...state, list: { list: [...state.list.list, ...data], total, isComplete } };
    }
    return { ...state, list: { isComplete: false, list: data, total } };
  }
  case RECEIVE_TREATMENT_SITE:
    return { ...state, current: action.payload }; 
  case RECIEVE_PATCH_TREATMENT_SITE: {
    const treatmentSite = action.payload;

    let newList = [...state.list.list];
    let index = state.list.list.findIndex(u => u.id === treatmentSite.id);
    if (index > -1) {      
      newList.splice(index, 1);
      newList = [...newList, treatmentSite];
    }

    let newFullList = [...state.fullList];
    index = newFullList.findIndex(u => u.id === treatmentSite.id);
    if (index > -1) {      
      newFullList.splice(index, 1);
      newFullList = [...newFullList, treatmentSite];
    }

    return {...state, current: treatmentSite, list: {list: newList}, fullList: newFullList};
  }
  case RECIEVE_CREATE_TREATMENT_SITE: {    
    return {...state, list: {list: []}, fullList: [...state.fullList, action.payload]};
  }
  case RECIEVE_DELETE_TREATMENT_SITE: {
    return {...state, list: {list: state.list.list.filter(treatmentSite=>treatmentSite.id !== action.payload)}, fullList: state.fullList.filter(treatmentSite=>treatmentSite.id !== action.payload)};
  }
  case RECEIVE_AUTH: {
    return {...state, fullList: action.payload.treatmentSites};
  }
  case CLEAR_CURRENT_TREATMENT_SITE: {
    return {...state, current: {}};
  }
  default:
    return state;
  }
};

export default treatmentSiteReducer;