import React from 'react';

export const LenderTable = (props) => (
  <div className='default-table' {...props}>
  </div>
);

export const HeadRow = (props) => (
  <div className='table-head-row' {...props}>
  </div>
);

export const Row = (props) => (
  <div className='table-row' {...props}>
  </div>
);

export const Cell = ({width, ...props}) => (
  <div className='table-cell' style={{width}} {...props}>
  </div>
);

export const SearchBar = (props) => (
  <input type='text' className='search-bar' placeholder='Search' {...props}/>
);

export const Header = () => (
  <div style={{display: 'table', width: 'calc(100%)'}}>
    <HeadRow>
      <Cell attributeName='name' width='40%'>Name</Cell>
      <Cell attributeName='Suburb' width='10%'>Suburb</Cell>
      <Cell attributeName='State' width='10%'>State</Cell>
      <Cell attributeName='PostCode' width='10%'>Post Code</Cell> 
      <Cell attributeName='State' width='10%'>Status</Cell>
      <Cell attributeName='State' width='10%'>Account No.</Cell>
      <Cell attributeName='State' width='10%'>Account Type</Cell>
    </HeadRow>
  </div>
);

export const TablePlaceHolder = () => [
  <PlaceHolderRow key={0}/>,
];


export const PlaceHolderRow = index => (
  <Row key={index}>
    <Cell width='15%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='20%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='12.5%'></Cell>
    <Cell width='15%'></Cell>
  </Row>
)


