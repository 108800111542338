import React, { Component } from 'react';
import { Row, Cell } from '../Patient';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => 
  bindActionCreators({    
  }, dispatch);

class PatientTableItem extends Component {

  shouldComponentUpdate(nextProps) {
    if (this.props.patient.id === nextProps.patient.id)
      return false;
    return true;
  }
  render() {
    const { patient, onClick, ...props } = this.props;

    // let patientStatus = 'active';
    // if (patient.signUpStage < 0) {
    //   patientStatus = 'deregistered';
    // } else if (patient.signUpStage < 3) {
    //   patientStatus = 'unregistered';
    // } else if (patient.signUpStage == 3) {
    //   patientStatus = 'registered';
    // } else if (patient.signUpStage == 4) {
    //   patientStatus = 'pending';
    // }

    return (<Row onClick={onClick} {...props}>

      <Cell width='16.6%'>{patient.initials || <span className='grey-text'>—</span>}</Cell>
      <Cell width='16.6%'>{patient.dob || <span className='grey-text'>—</span>}</Cell>
      <Cell width='16.6%'>{patient.sex || <span className='grey-text'>—</span>}</Cell>
      <Cell width='16.6%'>{patient.state || <span className='grey-text'>—</span>}</Cell>
      <Cell width='16.6%'>{patient.orderQuantity|| <span className='grey-text'>—</span>}</Cell>
      <Cell width='16.6%'>{patient.state || <span className='grey-text'>—</span>}</Cell>
    </Row>);
  }
}

export default connect(null, mapDispatchToProps) (PatientTableItem);

