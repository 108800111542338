import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Sidebar extends Component {

  componentDidMount() {
    let documentClick = false;

    window.$(document).on('touchstart', function() {
      documentClick = true;
    });

    window.$(document).on('touchmove', function() {
      documentClick = false;
    });
  }

  render() {
    return (
      <div className="sidebar large">
        <ul>
          <li><NavLink activeClassName="current" to="/hcp">HCPs</NavLink></li>
          <li><NavLink activeClassName="current" to="/patient">Patients</NavLink></li>
          <li><NavLink activeClassName="current" to="/treatment">Accounts</NavLink></li>
          <li><NavLink activeClassName="current" to="/order">Order History</NavLink></li>
          <li><NavLink activeClassName="current" to="/patientlist">Patient Table</NavLink></li>
          {/* <li><NavLink activeClassName="current" to="/stock">Stock Reports</NavLink></li> */}
          <li><NavLink activeClassName="current" to="/unconfirmed">Unconfirmed Orders</NavLink></li>
          <li><NavLink activeClassName="current" to="/hcplist">HCP table</NavLink></li>

        </ul>
      </div>
    )
  }
}

export default Sidebar;