
import { takeEvery, call, put } from 'redux-saga/effects';

import {
  findHcps,
  getUser,
  patchUser,
  createUser,
  deleteUser
} from './api';

import {
  receiveFindHcps,
  receiveUser,
  receivePatchUser,
  receiveCreateUser,
  receiveDeleteUser,
  clearCurrentHcp,

  REQUEST_FIND_HCPS,
  REQUEST_USER,
  REQUEST_PATCH_USER,
  REQUEST_CREATE_USER,
  REQUEST_DELETE_USER
} from './actions';



function* callFindHcps({ payload: { query, callback } }) {
  let result = yield call(findHcps, query);
  yield put(receiveFindHcps(result));
  if (callback)
    callback();
}

export function* findHcpsSaga() {
  yield takeEvery(REQUEST_FIND_HCPS, callFindHcps);
}

function* callGetUser({ payload: { query, callback } }) {
  yield put(clearCurrentHcp());
  let result = yield call(getUser, query);
  yield put(receiveUser(result));
  callback && callback(result);
}

export function* getUserSaga() {
  yield takeEvery(REQUEST_USER, callGetUser);
}

function* callPatchUser({ payload: { data, callback } }) {
  let result = yield call(patchUser, data);
  if (!result.error) {
    yield put(receivePatchUser(result));
  }
  callback && callback(result);
}

export function* patchUserSaga() {
  yield takeEvery(REQUEST_PATCH_USER, callPatchUser);
}

function* callCreateUser({ payload: { data, callback } }) {
  let result = yield call(createUser, data);
  console.log('new user:', result);
  if (!result.error) {
    yield put(receiveCreateUser(result));
  }
  callback && callback(result);
}

export function* createUserSaga() {
  yield takeEvery(REQUEST_CREATE_USER, callCreateUser);
}

function* callDeleteUser({ payload: { id, callback } }) {
  let result = yield call(deleteUser, id);
  console.log('delete user:', result);
  if (!result.error) {
    yield put(receiveDeleteUser(id));
  }
  callback && callback(result);
}

export function* deleteUserSaga() {
  yield takeEvery(REQUEST_DELETE_USER, callDeleteUser);
}