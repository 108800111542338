import { createAction } from 'redux-actions';

export const REQUEST_FIND_PATIENTS = 'REQUEST_FIND_PATIENTS';
export const RECEIVE_FIND_PATIENTS = 'RECEIVE_FIND_PATIENTS';
export const REQUEST_PATIENT = 'REQUEST_PATIENT';
export const RECEIVE_PATIENT = 'RECEIVE_PATIENT';
export const REQUEST_PATCH_PATIENT = 'REQUEST_PATCH_PATIENT';
export const RECIEVE_PATCH_PATIENT = 'RECIEVE_PATCH_PATIENT';
export const REQUEST_CREATE_PATIENT = 'REQUEST_CREATE_PATIENT';
export const RECIEVE_CREATE_PATIENT = 'RECIEVE_CREATE_PATIENT';
export const REQUEST_DELETE_PATIENT = 'REQUEST_DELETE_PATIENT';
export const RECIEVE_DELETE_PATIENT = 'RECIEVE_DELETE_PATIENT';
export const CLEAR_CURRENT_PATIENT = 'CLEAR_CURRENT_PATIENT';


export const requestFindPatients = createAction(REQUEST_FIND_PATIENTS);
export const receiveFindPatients = createAction(RECEIVE_FIND_PATIENTS);

export const requestPatient = createAction(REQUEST_PATIENT);
export const receivePatient = createAction(RECEIVE_PATIENT);

export const requestPatchPatient = createAction(REQUEST_PATCH_PATIENT);
export const receivePatchPatient = createAction(RECIEVE_PATCH_PATIENT);

export const requestCreatePatient = createAction(REQUEST_CREATE_PATIENT);
export const receiveCreatePatient = createAction(RECIEVE_CREATE_PATIENT);

export const requestDeletePatient = createAction(REQUEST_DELETE_PATIENT);
export const receiveDeletePatient = createAction(RECIEVE_DELETE_PATIENT);

export const clearCurrentPatient = createAction(CLEAR_CURRENT_PATIENT);