import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import NavButton from "components/NavButton";

import { requestLogout } from 'modules/user/actions';

function mapStateToProps(state) {
  return {
    user: state.user,
    router: state.router
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogout
  }, dispatch);
}

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuState: false,
      accountMenuState: false,
    };
  }

  logout() {
    this.props.requestLogout(() => {
    });
  }

  render() {
    let firstName = this.props.user.firstName || "";
    let lastName = this.props.user.lastName || "";

    return (
      <header className="sign-out">
        <div className="header-wrapper">
          <div className="left">
            <NavLink onClick={() => this.closeMenu()} to="/"><img className="logo" src={require('../img/medax/medax_logo.png')} alt="medax-logo" /></NavLink>
            <div className="site-type">Admin</div>
          </div>
          <div className="right">
            { this.props.user.id &&
              <NavButton onClick={() => this.logout()} className="button-type-2">Sign out</NavButton>
            }
          </div>

        </div>
      </header>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);