import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './App.css';
import Header from "components/Header";
import RoutesAuthenticated from 'view/RoutesAuthenticated';
import Sidebar from "components/Sidebar"
import { requestAuth, requestLogout } from 'modules/user/actions';
import lastAction from 'util/lastAction'
import { app } from "modules";
import moment from 'moment';

function mapStateToProps(state) {
  return {
    user: state.user,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestAuth,
    requestLogout
  }, dispatch);
}

function newNavigation(){

  if (window.performance.navigation) {
    return (window.performance.navigation.type === 0);
  }

  if (window.performance.getEntriesByType("navigation")){
     return window.performance.getEntriesByType("navigation")[0].type === 'navigate';     
  }

  return false;
}

class App extends Component {

  constructor() {
    super();
    this.state = {
      hasLoaded: false
    }
  }


  requestAuth(callback) {
    this.props.requestAuth({
      onSuccess: (user) => {
        if (!callback || !callback.doNotSetLoaded) {
          this.setState((prevState) => {
            return {...prevState, hasLoaded: true}
          });
        }
        
        callback && callback.onSuccess && callback.onSuccess(user);
      },
      onFail: (result)=>{
        if (!callback || !callback.doNotSetLoaded) {
          this.setState((prevState) => {
            return {...prevState, hasLoaded: true}
          });
        }
        callback && callback.onFail && callback.onFail(result);
      }
    });
  }


  componentWillMount() {
    setInterval(() => {
      this.checkServerStatus();
    }, 5000);

    if (newNavigation()) {
      this.requestAuth({
        doNotSetLoaded: true,
        onSuccess: ()=>{
          this.props.requestLogout({});
        },
        onFail: ()=>{
          this.setState((prevState) => {
            return {...prevState, hasLoaded: true}
          });
        }
      });
    } else {
      this.requestAuth();
    }
  }

  async checkServerStatus () {
    if (this.state.hasLoaded && this.props.user.id) {
      if (!app.passport.valid) {
        const token = await app.passport.getJWT();
        try {
          await app.passport.verifyJWT(token);
        } catch (e) {
          this.props.requestLogout({path: window.location.pathname.substr(1)});
        }
      }
      console.log(moment().diff(lastAction.get()));
      if (moment().diff(lastAction.get()) > 60 * 60 * 1000) {
        this.props.requestLogout({path: window.location.pathname.substr(1)});
      }
    }
  }

  render() {
    if (!this.state.hasLoaded) {
      return "";
    }
    
    return (
      <div>
        <Header />
        <RoutesAuthenticated isLoggedIn={!!this.props.user.id} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);