import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { reduxForm, SubmissionError } from 'redux-form';

import {requestLogin} from '../../modules/user/actions'
import getQueryParams from "util/getQueryParams";

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field } from "components/Forms"
import {_required, _email} from "util/validation"

let required = _required();
let email = _email();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogin,
  }, dispatch);
}

class Signin extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      let data = {
        password: results.password
      }

      data.email = results.username;
      this.props.requestLogin({
        data,
        callback: (result)=>{
          if (result.success) {
            if (getQueryParams.next) {
              this.props.history.push('/');
            }
            return resolve();
          } else {
            return reject(new SubmissionError({
                password: 'Invalid username or password.'
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="signin" title="Log In" isLoading={this.props.submitting} isLoading={this.props.submitting}>
        <p>Access to this site is restricted to administrators.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="username" type="text" title="Enter your email or phone number" validate={[required]} />
          <Field name="password" type="password" title="Enter your password" validate={[required]} />
          <Navigation primaryText="Log in" primaryLink="/" />
        </form>

      </ContainerBox>
    )
  }
}


Signin = reduxForm({
  form: 'Signin' // a unique name for this form
})(Signin);


export default connect(mapStateToProps, mapDispatchToProps)(Signin);