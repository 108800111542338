import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError, reset, change } from 'redux-form';

import { Field, Select, Checkbox } from "components/Forms"
import { title, state, profession, specialisation, specialistType, boolean, additonalContactRole } from "util/selectValues"
import NavButton from "components/NavButton";

import { requestPatchUser, requestUser, requestCreateUser } from 'modules/hcps/actions';
import { getQueryVariables } from 'util/index';
import filterInitialValues from 'util/filterInitialValues'

import { _required, _email, _mobile, _password } from "util/validation"
let required = _required();
let email = _email();
let mobile = _mobile();
let password = _password();

const requireYes = value => value !== 'false' ? undefined : 'Patient cannot be enrolled if requirement not met.';
let requiredGroup = (value, allValues) => {
  let exists = false;
  if (!allValues.user || !allValues.user.buvidal) return undefined;

  console.log(JSON.stringify(allValues.user.buvidal));
  for (let val of Object.keys(allValues.user.buvidal).filter(key => key.indexOf('additionalContact') !== -1)) {
    if (allValues.user.buvidal[val] !== undefined && allValues.user.buvidal[val] !== '') {
      exists = true;
    }
  }

  if (!exists) return undefined;
  return ((value && !(value.length === 0)) || typeof value === 'number' ? undefined : 'This field is required');
}
let requiresListedSite = (value, allValues, props) => {
  if (!allValues.user || !allValues.user.buvidal || !allValues.user.buvidal.isValidated) return undefined;

  let treatmentSite = props.treatmentSites.filter(site=>site.value === value)[0];
  
  if (!treatmentSite || !treatmentSite.isActive) {
    return "Treatment site must be 'listed' and 'active' before HCP is allowed to enrol patients."
  }
  return undefined;
};

function mapStateToProps(state, ownProps) {
  let treatmentSites = state.treatmentSites.fullList && state.treatmentSites.fullList;
  treatmentSites = treatmentSites.map(site=>({value:site.id, label:site.name, ...site}));

  if (treatmentSites.length) {
    treatmentSites.sort((_a, _b) => {
      const a = _a.label.toLowerCase();
      const b = _b.label.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
  }

  let initialValues = {};
  if (!ownProps.isCreate) {
    initialValues = {
      user: state.hcps.current
    }
  }

  console.log(initialValues);
  if (initialValues.user && initialValues.user.buvidal && !initialValues.user.buvidal.maxPatients) {
    initialValues.user.buvidal.maxPatients = 10;
  }

  return {
    user: state.hcps.current,
    treatmentSite: (state.form.HcpForm && state.form.HcpForm.values && state.form.HcpForm.values.user && state.form.HcpForm.values.user.buvidal) ? state.form.HcpForm.values.user.buvidal.treatmentSite : null,
    treatmentSites,
    initialValues,
    fields: (state.form.HcpForm && state.form.HcpForm.registeredFields) || {},
    HcpForm: (state.form.HcpForm) || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    requestCreateUser,
    requestUser,
    reset,
    change,
  }, dispatch);
}

const addressFields = [
  {userKey: 'clinicPracticeName', siteKey : 'name',},
  {userKey: 'AddressLine_1', siteKey : 'Address_1',},
  {userKey: 'AddressLine_2', siteKey : 'Address_2',},
  {userKey: 'suburb', siteKey : 'Suburb',},
  {userKey: 'postcode', siteKey : 'Postcode',},
  {userKey: 'state', siteKey : 'State',},
  {userKey: 'phone', siteKey : 'Phone',},
  {userKey: 'specialDeliveryInstructions', siteKey : 'Special_Shipping_Instructions'},
];


class HcpForm extends Component {

  componentWillMount() {
    const { id } = getQueryVariables(window.location.search);
    this.props.requestUser({query: id, callback: () => {
      this.setState({ loaded: true });
      this.props.reset();
    }});
    this.setState({ userId: id });
  }

  submit(_results) {
    return new Promise((resolve, reject) => {
      // if (_results.user.password) {
      //   if (_results.user.password !== _results['password_confirm']) {
      //     return reject(new SubmissionError({
      //       'password': 'Passwords do not match'
      //     }));
      //   }
      // }

      console.log(JSON.stringify(_results, null, 2));
      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.history.push(`/hcp/success${window.location.search}`)
        return resolve();
      }

      if (results.user.buvidal && results.user.buvidal.treatmentSite === 0) {
        results.user.buvidal.treatmentSite = null;
      }

      if (this.props.isCreate) {
        results.user.signupStage = 3;
      }

      let apiFunction = this.props.isCreate ? this.props.requestCreateUser : this.props.requestPatchUser;

      apiFunction({
        data: {...results.user, id: this.props.user.id},
        callback: (result) => {
          if (!result.error) {
            this.props.history.push(`/hcp/success/${window.location.search || '?type=create'}`)
            return resolve();
          } else {
            this.scrollToError();
            return reject(new SubmissionError(result.error.data.validation));
          }
        }
      });
    });
  }

  scrollToError() {
    setTimeout(() => {
      if (window.$('.error').length) {
        window.$('.content').animate({
            scrollTop: window.$('.error').offset().top + 800
        }, 200);
      }
    }, 10);
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      console.log('error');
      this.scrollToError();
    }
    return false;
  }

  clearLocation() {
    console.log('state changed!');
    this.props.dispatch(change('HcpForm', 'user.buvidal.treatmentSite', 0));
  }

  onSiteChanged(value) {
    const site = this.props.treatmentSites.find(s => s.value === value);
    if (site) {
      const field_name = site.account_type === 'HCL' ? 'hcl_account_number' : 'symbion_account_number';
      this.props.dispatch(change('HcpForm', 'user.buvidal.' + field_name, site.Account_number));


      
      if (site.account_type === 'HCL') {
        for (const field of addressFields) {
          this.props.dispatch(change('HcpForm', 'user.buvidal.' + field.userKey, site[field.siteKey]));
        }
      }
    }
  }

  onAcconutTypeChanged(value) {
    for (const field of addressFields) {
      this.props.dispatch(change('HcpForm', 'user.buvidal.' + field.userKey, ''));
    }
  }

  render() {
    const communications = [
      { value: 'email', label: "Email" },
      { value: 'mobile-number', label: "Mobile Number" },
    ];

    const authorisation = [
      { value: 'true', label: 'Authorised to prescribe opioid dependence treatments in my state.' },
    ];

    const educationAuthorisation = [
      { value: 'true', label: 'Read the mandatory Buvidal Weekly/Monthly education in its entirety, and understand the appropriate use of Buvidal.' },
    ]

    const patientEnrolment = [
      { value: '1', label: 'Allow HCP\'s to enrol patients.' },
    ]

    if (!this.props.isCreate && !(this.props.user && this.props.user.id)) {
      return '';
    }

    const order = [
      { value: 'direct', label: 'HCL' },
      { value: 'pharmacy', label: 'Symbion' },
    ]

    const {order_shipping} = this.props.HcpForm && this.props.HcpForm.values && this.props.HcpForm.values.user && this.props.HcpForm.values.user.buvidal ?  this.props.HcpForm.values.user.buvidal : {undefined}
    const props = this.props;
    const treatmentSiteOptions = this.props.treatmentSites.filter(site=>order_shipping && ((site.account_type === 'HCL' && order_shipping === 'direct') || (site.account_type === 'Symbion' && order_shipping === 'pharmacy')));

    const {specialisation: specialisation_value} = this.props.HcpForm && this.props.HcpForm.values && this.props.HcpForm.values.user && this.props.HcpForm.values.user.meta ?  this.props.HcpForm.values.user.meta : {}
    const isOtherSelected = (specialisation_value || []).find(e => e === 'Other')

    const clearTreatmentsiteHandler = (e) => {
      if(e === 'pharmacy' && this.props.HcpForm.values.user) {
        this.props.HcpForm.values.user.buvidal.clinicPracticeName = ''
        this.props.HcpForm.values.user.buvidal.AddressLine_1 = ''
        this.props.HcpForm.values.user.buvidal.AddressLine_2 = ''
        this.props.HcpForm.values.user.buvidal.clinicPracticeName = ''
        this.props.HcpForm.values.user.buvidal.suburb = ''
        this.props.HcpForm.values.user.buvidal.postcode = ''
        this.props.HcpForm.values.user.buvidal.state = ''
        this.props.HcpForm.values.user.buvidal.phone = ''
        this.props.HcpForm.values.user.buvidal.specialDeliveryInstructions = ''
        this.props.HcpForm.values.user.buvidal.hcl_account_number = ''
        this.props.HcpForm.values.user.buvidal.symbion_account_number = ''
        this.props.HcpForm.values.user.buvidal.treatmentSite = ''
      }
    }


    return (
      <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
        <input hidden autoComplete="off" />
        { this.props.isCreate &&
          [<input id="username" style={{height: 0, width: 0, opacity: 0}} type="text" name="fakeusernameremembered" />,
          <input id="password" style={{height: 0, width: 0, opacity: 0}} type="password" name="fakepasswordremembered" />]
        }
        <h2>Status</h2>
        <Checkbox styling="float" name="user.buvidal.isValidated" options={patientEnrolment} single />
        <Field styling="float r" name="user.buvidal.maxPatients" type="number" title="Maximum Patients" />    

        <h2>MedAX details</h2>
        <h3>User Registration</h3>
        <Field styling="float l" name="user.email" type="email" title="Email address" validate={[required]} />
        <Field styling="float r" name="user.mobileNumber" type="tel" title="Mobile number" validate={[required]} />
        <Field styling="float l" name="user.password" type="password" title="Password" validate={[password]} />
        {/* <Field styling="float r" name="password_confirm" type="password" title="Confirm Password" /> */}

        <h3>User details</h3>
        <Select styling="float l" name="user.title" type="text" title="Title" options={title} validate={[required]} />
        <Field styling="float r" name="user.firstName" type="text" title="First name" validate={[required]} />
        <Field styling="float l" name="user.lastName" type="text" title="Last name" validate={[required]} />

        <Field styling="float r" name="user.ahpra" type="text" title="AHPRA Number" validate={[required]} />
        <Select styling="float l " name="user.state" type="text" title="State" options={state} validate={[required]} onChange={() => this.clearLocation()}/>
        <Select styling="float r" name="user.profession" type="text" title="Profession" options={profession} validate={[required]} />
        <Select styling="float l" name="user.meta.specialisation" type="text" title="Specialisation" options={specialisation} isMulti validate={[required]} />
        {isOtherSelected && <Field styling="float r" name="user.meta.specialisation_other" type="text" title="Other specialisation" validate={[required]} />}
        <Checkbox radio styling="float" name="user.meta.alertPreferences" title="Alert HCP's to other Product Familiarisation Programs?" options={boolean} />

        <h2>Buvidal details</h2>
        <Checkbox styling="float" name="user.buvidal.communications" title="Please select HCP's preferred option for notifications." options={communications} validate={[]} />

        <h3>Drug order account</h3>
        <Checkbox styling="float" radio name="user.buvidal.order_shipping" options={order} onChange={clearTreatmentsiteHandler} validate={[required]} />
        
        {(order_shipping === 'direct' || order_shipping === 'pharmacy') &&
          <Select styling="float l" name="user.buvidal.treatmentSite" type="text" title="Account" onChange={(value=>this.onSiteChanged(value))} options={[...treatmentSiteOptions]} validate={[requiresListedSite, ]} />
        }

        {order_shipping === 'direct' &&
          <Field styling="float r" name="user.buvidal.hcl_account_number" type="text" title="HCL account number" disabled />          
        }
        {order_shipping === 'pharmacy' &&
          <Field styling="float r" name="user.buvidal.symbion_account_number" type="text" title="Symbion account number" disabled />
        }

        <h3>Treatment site</h3>
        <Field name="user.buvidal.clinicPracticeName" type="text" title="Clinic/Practice name" validate={[required]}/>
        <Field styling="float l" name="user.buvidal.AddressLine_1" type="text" title="Address Line 1" validate={[required]}/>
        <Field styling="float r" name="user.buvidal.AddressLine_2" type="text" title="Address Line 2" />          
        <Field styling="float l" name="user.buvidal.suburb" type="text" title="Suburb" validate={[required]}/>
        <Field styling="float r" name="user.buvidal.postcode" type="number" title="Postcode" validate={[required]}/>          
        <Select styling="float l" options={state} name="user.buvidal.state" type="text" title="State" validate={[required]}/>          
        <Field styling="float r" name="user.buvidal.phone" type="text" title="Phone" validate={[required]}/>          
        <Field name="user.buvidal.specialDeliveryInstructions" type="text" title="Special delivery instructions" />          

        {/* {(this.props.treatmentSite !== null && this.props.treatmentSite === -1) &&
          <Field styling="float r" name="user.buvidal.treatmentSiteOther" type="text" title="Other treatment site name" validate={[]} />
        } */}
        {/* <Field styling="float r" name="user.buvidal.prescriberNumber" type="text" title="Prescriber number" validate={[]} /> */}

        {(order_shipping === 'direct' || order_shipping === undefined) &&
          <>
          <h3>Additional contact</h3>
            <Field styling="float l" name="user.buvidal.additionalContact_firstName" type="text" title="First name" validate={[requiredGroup]} />
            <Field styling="float r" name="user.buvidal.additionalContact_lastName" type="text" title="Last name" validate={[requiredGroup]} />
            <Field styling="float l" name="user.buvidal.additionalContact_emailAddress" type="email" title="Email address" autoCapitalize="none" autoCorrect="none" validate={[requiredGroup, email]} />
            <Field styling="float r" name="user.buvidal.additionalContact_mobileNumber" type="tel" title="Mobile number" validate={[requiredGroup, mobile]} />
            <Select isClearable styling="float l" name="user.buvidal.additionalContact_role" type="text" title="Role" options={additonalContactRole} validate={[requiredGroup]} />
            <Field styling="float r" name="user.buvidal.additionalContact_specifyRole" type="text" title="Role in PFP" />
           </>
        }
{/* 
        <h3>Authorisation</h3>`
        <Checkbox styling="float" name="authorisation" options={authorisation} validate={[required]} single />

        <h3>Education Module</h3>
        <Checkbox styling="float" name="authorisation" options={educationAuthorisation} validate={[required]} single /> */}

        <div className="navigation">
          <button type="submit" className="primary button-type-1" disabled={this.props.pristine}>{this.props.buttonName}</button>
          {this.props.type == "update"?
            <NavButton className="secondary button-type-3" to={"/hcp/delete/" + window.location.search}>Delete</NavButton>
            :""
          }
        </div>

      </form>
    );
  }
}

HcpForm = reduxForm({
  enableReinitialize: true,
  form: 'HcpForm' // a unique name for this form
})(HcpForm);

export default connect(mapStateToProps, mapDispatchToProps)(HcpForm);
