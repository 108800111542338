import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { Checkbox } from "components/Forms"
import { boolean } from "util/selectValues";

import Sidebar from "components/Sidebar";
import { requestDeletePatient, requestPatient } from 'modules/patient/actions';
import { getQueryVariables } from 'util/index';

import { _required, _email, _mobile } from "util/validation"
let required = _required();
const requireYes = value => value === 'true' ? undefined : 'You must select "Yes" to delete the patient.';

function mapStateToProps(state) {
  return {
    patient: state.patients.current,
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestDeletePatient,
    requestPatient
  }, dispatch);
}


class PatientDelete extends Component {

  componentWillMount() {
    const { id } = getQueryVariables(window.location.search);
    this.props.requestPatient({query: id, callback: () => {
      this.props.reset();
    }});
    this.setState({ patientId: id });
  }

  submit(_results) {
    return new Promise((resolve, reject) => {

      if (_results.confirmDelete !== "true") {
        return reject(new SubmissionError({
          confirmDelete: 'You must select "Yes" to delete the patient.'
        }));
      }

      this.props.requestDeletePatient({
        id: this.props.patient.id,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push(`/patient/success?type=delete`)
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {
    if (!this.props.patient.id) {
      return "";
    }
    return (
      <main className={"dashboard details-page"}>
        <Sidebar />
        <div className="content">
          <div className='default-form'>
            <h1>Delete Patient</h1>
            <p>Patient: {this.props.patient.initials}</p>
            <p>This will delete the patient.</p>
              <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
              <Checkbox validate={[required, requireYes]} radio title="Do you wish to continue?" styling="float r" name="confirmDelete" options={boolean} />
              <div className="navigation">
                <button type="submit" className="button-type-1">Delete</button>
              </div>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

PatientDelete = reduxForm({
  form: 'PatientDelete' // a unique name for this form
})(PatientDelete);

export default connect(mapStateToProps, mapDispatchToProps)(PatientDelete);
