import React, { Component } from 'react';
import { Debouncer } from 'util/index';
import PropTypes from 'prop-types';
import { Field, Select, Checkbox } from "components/Forms";
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';

// import styled from 'styled-components';
// import imgSearch from 'img/icons/icon-search-blue.png'; 
// import TextInput from 'components/Styled/TextInput';

// const SearchInput = styled(TextInput)`
//   padding-left: 35px;
//   background-image: url(${imgSearch});
//   background-repeat: no-repeat;
//   background-position: 10px 50%;
//   background-size: 15px auto;
// `;

class SearchBar extends Component {
  constructor() {
    super();
    this.debouncer = new Debouncer();
  }

  render() {
    const {placeholder, width, onChange, msDebounce, ...props} = this.props;
    return (
      <form onSubmit={()=>false}>
        <div className="field">
          <input id="search" type="text" style={{margin: 0}} placeholder="Search"
            onChange= {async e => {
              console.log("test");
              const search = e.target.value;
              this.debouncer.run(() => onChange(search), msDebounce || 0);
            }}
          />
        </div>
      </form>

    );
  }
} 

SearchBar.propTypes = {
  width: PropTypes.string,
  placeholder: PropTypes.string,
  msDebounce: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

SearchBar = reduxForm({
  form: 'SearchBar' // a unique name for this form
})(SearchBar);

export default connect(null, null)(SearchBar);